
.registrationDetail {
    padding: 10px 0;
    background-image: url("../../../assets/img/banner/vtech2-bg.jpg");
    background-repeat: no-repeat;
    height: 100vh;
    position: relative;
  }
  
  .registrationInfo {
  
    display: flex;
    align-items: stretch;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    box-shadow: 0px 15px 10px -15px #a9a9a9;
    width: 50%;
  }
  
  .registerContent {
    background: linear-gradient(to bottom, #2874f0, #1a55b7);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  
    flex: 1 1;
    padding: 40px 33px;
  }
  
  .registerForm{
    background: #fff;
    border: 1px solid #ededed;
    border-left: 0;
    border-bottom: 0;
    flex: 1 1;
    padding: 40px 33px;
  }
  button.btn.btn-primary.createAccount{
    width: 100%;
    background: #fb641b;
    border-color: #fb641b;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 15px;
    padding: 10px 0;
    letter-spacing: 1px;
  }
  .joinWith{
    text-align: center;
    margin: 17px 0;
    position: relative;
  }