.header_top {
  position: relative;
}
.header_top {
  background: #004ea2;
  background-size: 100% 100%;
  border: none;
}
.header_top {
  min-height: 50px;
}
.full {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
}
.topbar-left {
  float: left;
}
.header_top .topbar-left .list-inline {
  float: left;
  margin: 13px 0;
}
.header_top ul {
  list-style: none;
  margin: 10px 0;
  padding: 0;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.header_top ul li {
  font-size: 13px;
  color: #fff;
  float: left;
  margin-right: 20px;
}
.header_top ul li {
  font-size: 14px;
  color: #fff;
  float: left;
}
.header_top ul li i {
  color: #fff;
  transition: ease all 0.2s;
  font-size: 18px;
  margin-right: 5px;
}
.header_top ul li i {
  margin: 0 2px 0 0;
}
.topbar-hightlight a {
  color: #fff;
}
.float-left {
  float: left !important;
}
.social_icon {
  float: left;
  margin: 2px 0 0 0;
}
.social_icon ul {
  list-style: none;
  float: left;
}
.header_top ul {
  list-style: none;
  margin: 10px 0;
  padding: 0;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.social_icon ul li {
  display: inline;
  font-size: 15px;
  margin-right: 15px;
}
.float-right {
  float: right !important;
}
.make_appo {
  float: right;
}
.make_appo .btn.white_btn {
  margin: 0;
  border-radius: 0;
  font-weight: 600;
  padding: 0 35px;
  font-family: "Raleway", sans-serif;
}
a.btn.white_btn {
  color: #111;
  text-transform: none;
  text-shadow: none;
  background-color: #fff;
}
.fa-envelope-o:before {
  content: "\f003";
}
.facebook:before {
  content: "\f09a" !important;
}
.fa-google-plus:before {
  content: "\f0d5";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-linkedin:before {
  content: "\f0e1";
}
.fa-instagram:before {
  content: "\f16d";
}
.list-inline li svg {
  font-size: 20px;
}
.float-right .make_appo .white_btn {
  min-width: 170px;
  height: 52px;
  line-height: 52px;
  font-size: 14px;
}
