.innerShipping {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
}

.showShippingAdd {
    width: calc(50% - 20px);
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
}

.activeAdd {
    background-color: rgb(166, 166, 166);
    color: black;
    font-size: 18px;
}

.showShippingAdd:hover {
    /* background-color: rgb(252, 250, 250); */
    position: relative;
}

.shippingDelete {
    position: absolute;
    top: 1px;
    right: 0px;
    border-radius: 100%;
}
.shippingDelete > button:hover{
    cursor: pointer;
}

.activeAddres{
    background-color: #004ea2c4;
    padding: 20px;
}
.succesful{
    color: green;
}

.payTingg{
    align-items: center;
    margin-bottom: 10px !important;
}
.payTingg > h6 {
    margin: 0;
    cursor: default;
}

.paymentProcess{
    /* background-color: aqua; */
}
.payRadio > div >div{
    margin: 20px 0;
}
.modalCards{
    display: flex;
    justify-content: space-between;
}
.modalCards > div{
    width: 50% !important;
}
.modalCards > .cardRight{
    text-align: left;
}