.aboutCompanyText {
    margin-bottom: 40px;
    padding-bottom: 15px;
    border-bottom: 1px solid #519b59;
}

.aboutCompanyText h3 {
    font-size: 17px;
    color: #1a1a1a;
    text-transform: uppercase;
}
.aboutCompanyText p {
    color: #f1f1f1;
}
.footer-section.footer-bg .single-footer-widget .footer-social a.facebook{background-color: #3c579e;}
.footer-section.footer-bg .single-footer-widget .footer-social a.twitter{background-color: #1cb8ec;}
.footer-section.footer-bg .single-footer-widget .footer-social a.instagram{background-color: #ec0a68;}
.footer-section.footer-bg .single-footer-widget .footer-social a.likedin{background-color: #1686b0;}
.footer-section.footer-bg .single-footer-widget .footer-social a.pinterest{background-color: #b7081b;}

@media (max-width: 500px){
    .aboutCompanyText, .single-footer-widget{
        text-align: center;
    }
    .footer-info-contact{
        text-align: left;
    }
    .copyright-area .copyright-area-content{
        text-align: center;
    }
    .copyright-area .copyright-area-content ul{
        text-align: center;
    }
    .single-footer-widget .footer-info-contact {
        justify-content: center;
        text-align: center;
    }
    .single-footer-widget .footer-info-contact .topIcon svg{
        display: none;
    }
}